import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-azie',
  templateUrl: './help-azie.component.html',
  styleUrls: ['./help-azie.component.scss']
})
export class HelpAzieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
