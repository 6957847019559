import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavTopComponent } from './nav/nav-top/nav-top.component';
import { NavFooterComponent } from './nav/nav-footer/nav-footer.component';
import { HelpDocComponent } from './help-doc/help-doc.component';
import { HelpPartComponent } from './help-part/help-part.component';
import { HelpAzieComponent } from './help-azie/help-azie.component';
import { HelpEnteComponent } from './help-ente/help-ente.component';
import { HelpTutoComponent } from './help-tuto/help-tuto.component';

@NgModule({
  declarations: [
    AppComponent,
    NavTopComponent,
    NavFooterComponent,
    HelpDocComponent,
    HelpPartComponent,
    HelpAzieComponent,
    HelpEnteComponent,
    HelpTutoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
