<div class="wrapper">
  <app-nav-top></app-nav-top>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0"> HELP  <small>Portal Partecipanti</small></h1>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Calendario CORSI</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile visualizzare il proprio calendario corsi</h6>

                <p class="card-text">
                  la visualizzazione è possibile nelle modalità: mensile, settimanale, giornaliera
                </p>
                <div class="row">
                  <div class="col-lg-4">
                    <img src="assets/imgs/visualmenspart.png" alt="Visualizzazione mensile" class="img-fluid" style="opacity: .8">
                  </div>
                  <div class="col-lg-4">
                    <img src="assets/imgs/visualsettpart.png" alt="Visualizzazione settimanale" class="img-fluid"  style="opacity: .8">
                  </div>
                  <div class="col-lg-4">
                    <img src="assets/imgs/visualgiopart.png" alt="Visualizzazione giornaliera" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
            </div>
          </div>

            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Storia Formativa</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile accedere alla prorpia storia formativa</h6>
                <p class="card-text">
                  Sono disponibili due modalità di visualizzazione: per settore e per corso.
                </p>
                <div class="row">
                  <div class="col-lg-6">
                    <img src="assets/imgs/stoformsettpart.png" alt="stoformsettpart" class="img-fluid" style="opacity: .8">
                  </div>
                  <div class="col-lg-6">
                    <img src="assets/imgs/stoformcorspart.png" alt="stoformcorspart" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <br />
                <p class="card-text">
                  Inoltre è possibile consultare e scaricare i propri attestati.
                </p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/attestatipart.png" alt="attestatipart" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p class="card-text">
                  Selezionando la riga della griglia è possibile visualizzare l'anteprima dell'attestato
                </p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/previewdoc.png" alt="previewdoc" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Materiale Didattico</h5>
              </div>
              <div class="card-body">
                <p class="card-text">In questa sezione è possibile consultare il materiale didattico caricato dal docente e/o dall'ente</p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/materialedidpart.png" alt="materialedidpart" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Questionari</h5>
              </div>
              <div class="card-body">
                <p class="card-text">In questa sezione è possibile accedere alla compilazione dei questionari on-line</p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/listquest.png" alt="listquest" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p class="card-text">le righe in verde, sono i questionari già compilati dal partecipante</p>
                <p></p>
                <div class="row>">
                  <div class="col-lg-12">
                    <img src="assets/imgs/execquest.png" alt="execquest" class="img-fluid"  style="opacity: .8">
                    Per compilare il questionario è necessario premere il tasto con il simbolo [Lista] a sinistra sulla griglia dei questionari
                    <p></p>
                    <img src="assets/imgs/questpart.png" alt="questpart" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p></p>
                <p class="card-text">Una volta compilato il questionario con le risposte appare il messaggio di conferma esecuzione.</p>
                <div class="row>">
                  <div class="col-lg-12">
                    <img src="assets/imgs/msgquest.png" alt="msgquest" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p></p>
                <p class="card-text">Completato il questionario la riga verrà colorata di verde e non sarà piu accessbile.</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <app-nav-footer></app-nav-footer>
</div>
