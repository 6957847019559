<!-- Navbar -->
<nav class="main-header navbar navbar-expand-md navbar-light navbar-white">
  <div class="container">
    <a href="#" class="navbar-brand">
      <img src="assets/imgs/logo.png" alt="Socrate Logo" class="brand-image " style="opacity: .8">
      <span class="brand-text font-weight-light">&nbsp;</span>
    </a>
  </div>
</nav>
<!-- /.navbar -->
