<div class="wrapper">
  <app-nav-top></app-nav-top>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0"> HELP  <small>Portal Tutor</small></h1>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Lista Corsi</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile consultare tutti corsi assegnati ai tutor</h6>
                <p class="card-text">
                  la visualizzazione comprende una griglia con l'elenco dei corsi dove tramite i pulsanti in riga è possibile effetuare le seguenti operazioni:
                </p>
                <ul>
                  <li>
                    <img src="assets/imgs/plus.png" alt="stoformsettazie" class="img-fluid" style="opacity: .8"> Esplosione in sotto livelli: Modulo -> Docente</li>
                  <li>
                    <img src="assets/imgs/calendar.png" alt="stoformsettazie" class="img-fluid" style="opacity: .8">
                    Calendario Corso
                  </li>
                </ul>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/listacorsi_tutor.png" alt="stoformsettazie" class="img-fluid" style="opacity: .8">
                  </div>
                </div>
                <br />
                <p class="card-text">
                  Griglia a sottolivelli
                </p>
                <p class="card-text">
                  Modulo -> Docente
                  <img src="assets/imgs/subgrid.png" alt="widget" class="img-fluid" style="opacity: .8">
                </p>
                <p class="card-text">
                  Calendario Corso
                </p>
                <img src="assets/imgs/calendar_tutor.png" alt="gridbar" class="img-fluid" style="opacity: .8">
                <p class="card-text">
                  con le funzionalità seguenti:
                </p>
                <ul>
                  <li>Possibiltà di effettuare uno spostamento dell'appuntamento in modalità MENSILE / SETTIMANALE</li>
                  <li>Possibiltà di effettuare uno aumento o riduzione delle ore appuntamento SETTIMANALE / GIORNALIERA </li>
                  <li>
                    Creazione di un nuovo appuntamento (da confermare)
                    <img src="assets/imgs/creaappunt.png" alt="toolbar" class="img-fluid" style="opacity: .8">
                  </li>
                </ul>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-nav-footer></app-nav-footer>

</div>
