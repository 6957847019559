<div class="wrapper">
  <app-nav-top></app-nav-top>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0"> HELP  <small>Portal Aziende</small></h1>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Storia Formativa</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile visualizzare la storia formativa per i propri dipendenti</h6>
                <p class="card-text">
                  la visualizzazione è possibile nelle modalità: settore, corso, dipendenti, mansioni, ruoli.
                </p>
                <div class="row">
                  <div class="col-lg-4">
                    <img src="assets/imgs/stoformsettazie.png" alt="stoformsettazie" class="img-fluid" style="opacity: .8">
                  </div>
                  <div class="col-lg-4">
                    <img src="assets/imgs/stoformcorsazie.png" alt="stoformcorsazie" class="img-fluid"  style="opacity: .8">
                  </div>
                  <div class="col-lg-4">
                    <img src="assets/imgs/stoformdipeazie.png" alt="Visualizzazione giornaliera" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-lg-6">
                    <img src="assets/imgs/stoformmansazie.png" alt="stoformmansazie" class="img-fluid" style="opacity: .8">
                  </div>
                  <div class="col-lg-6">
                    <img src="assets/imgs/stoformruolazie.png" alt="stoformruolazie" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <br />
                <p class="card-text">
                  Per ogni modalià di visualizzazione è possibile vedere le presenze effetuate per il corso selezionado la riga della griglia.
                </p>
                <p class="card-text">
                  Una volta selezionata la riga corrispondente verranno visualizzate le presenze con il totale delle ore nei box laterali.
                  <img src="assets/imgs/widget.png" alt="widget" class="img-fluid" style="opacity: .8">
                </p>
                <p class="card-text">
                  In tutte le visualizzazioni è presente una toolbar in griglia:
                </p>
                <img src="assets/imgs/gridbar.png" alt="gridbar" class="img-fluid" style="opacity: .8">
                <p class="card-text">
                  la quale permette di visualizzare:
                </p>
                <ul>
                  <li>
                    <p class="card-text">il dettaglio delle presenze</p>
                    <img src="assets/imgs/dettpresenze.png" alt="dettpresenze" class="img-fluid" style="opacity: .8">
                  </li>
                  <li>gli attestati del partecipante</li>
                </ul>
                <p class="card-text">
                  Nella parte soprastante delle griglie tramite la toolbar di riferimento
                </p>
                <p></p>
                <div class="row">
                  <div class="col-lg-12">
                    <ul>
                      <li>
                        <img src="assets/imgs/toolbar1.png" alt="toolbar" class="img-fluid" style="opacity: .8">
                        Visualizza i corsi con data di scadenza abilitazione piu recente  (visualizzazione standard)
                      </li>
                      <li>
                        <img src="assets/imgs/toolbar2.png" alt="toolbar" class="img-fluid" style="opacity: .8">
                        Visualizza i tutti i corsi (anche quelli scaduti)
                      </li>
                      <li>
                        <img src="assets/imgs/toolbar3.png" alt="toolbar" class="img-fluid" style="opacity: .8">
                        Materiale Didattico
                      </li>
                    </ul>
                  </div>
                </div>
                <p class="card-text">
                  è possibile nascondere o visualizzare tutti i corsi effettuati o solo quelli attivi, oppure visualizzare il materiale docente
                </p>
              </div>
            </div>
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Attestati</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile visualizzare e scaricare gli attestati per i propri dipendenti</h6>
                <p class="card-text">
                  &nbsp;
                </p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/attestatiazie.png" alt="attestatiazie" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p></p>
                <p class="card-text">
                  Selezionando la riga si visualizza l'anteprima del documento.
                </p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/previewdoc.png" alt="previewdoc" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-nav-footer></app-nav-footer>
</div>
