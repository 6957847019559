import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-part',
  templateUrl: './help-part.component.html',
  styleUrls: ['./help-part.component.scss']
})
export class HelpPartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
