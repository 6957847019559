<div class="wrapper">
  <app-nav-top></app-nav-top>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0"> HELP  <small>Portal Docenti</small></h1>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container">
        <div class="row">

          <!-- /.col-md-6 -->
          <div class="col-lg-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Calendario CORSI/CONSULENZE</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile visualizzare il calendario docenti/consulenti</h6>

                <p class="card-text">
                  la visualizzazione è possibile nelle modalità: mensile, settimanale, giornaliera
                </p>
                <div class="row">
                  <div class="col-lg-4">
                    <img src="assets/imgs/visualmens.png" alt="Visualizzazione mensile" class="img-fluid" style="opacity: .8">
                  </div>
                  <div class="col-lg-4">
                    <img src="assets/imgs/visualsett.png" alt="Visualizzazione settimanale" class="img-fluid"  style="opacity: .8">
                  </div>
                  <div class="col-lg-4">
                    <img src="assets/imgs/visualgio.png" alt="Visualizzazione giornaliera" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p class="card-text">&nbsp;</p>
                <p class="card-text">Esitono due tipologie di appuntamento: Lezione corso (colorazione blu) e consulenza (colorazione gialla)</p>
                <div class="row">
                  <div class="col-lg-6">
                    <img src="assets/imgs/visualsett.png" alt="lezione" class="img-fluid" style="opacity: .8">
                  </div>
                  <div class="col-lg-6">
                    <img src="assets/imgs/visualsett2.png" alt="consulenza" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p class="card-text">&nbsp;</p>
                <p class="card-text">nella gestione delle consulenze è possibilie aprire l'appuntamento e completare il "rapportino" con le ore eseguite.</p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/rapportcons.png" alt="consulenza2" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <p class="card-text">Una volta completato verrà generata una mail che verrà inviata al cliente finale e l'appuntamento di consulenza verrà colorato di verde.</p>

              </div>
            </div>
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h5 class="card-title m-0">Materiale Didattico</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title">In questa sezione è possibile caricare il materiale didattico generico o specifico per corso.</h6>
                <p class="card-text">
                  la visualizzazione comprende due griglie di visualizzazione:
                </p>
                <div class="row">
                  <div class="col-lg-12">
                    <img src="assets/imgs/materialedid.png" alt="materialedid" class="img-fluid"  style="opacity: .8">
                  </div>
                </div>
                <br/>
                <ul>
                  <li>
                    la prima griglia visualizza il materiale effettivamente caricato.
                    <img src="assets/imgs/loadeddoc.png" alt="loadeddoc" class="img-fluid"  style="opacity: .8">
                    <p class="card-text">Selezionando il file dalla griglia è possibile visualizzare l'anteprima del file</p>
                    <img src="assets/imgs/previewdoc.png" alt="previewdoc" class="img-fluid"  style="opacity: .8">
                    <div class="row>">
                      <div class="col-lg-12">
                        <img src="assets/imgs/uploadgen.png" alt="uploadgen" class="img-fluid"  style="opacity: .8">
                        Per caricare il materiale didattico generico è necessario premere il tasto con il simbolo [+] che si trova in basso nella barra di paginazione
                        <img src="assets/imgs/docgeneric.png" alt="docgeneric" class="img-fluid"  style="opacity: .8">
                      </div>
                    </div>
                  </li>
                  <br/>
                  <li>
                    la seconda griglia premette di caricare il materiale diddattico specifico per corso.
                    <img src="assets/imgs/listcors.png" alt="listcors" class="img-fluid"  style="opacity: .8">
                    <p></p>
                    <div class="row>">
                      <div class="col-lg-12">
                        <img src="assets/imgs/uploadspec.png" alt="uploadspec" class="img-fluid"  style="opacity: .8">
                        Per caricare il materiale didattico generico è necessario premere il tasto con il simbolo [Freccia SU] a sinistra sulla griglia dei corsi
                        <p></p>
                        <img src="assets/imgs/docspec.png" alt="docspec" class="img-fluid"  style="opacity: .8">
                      </div>
                    </div>
                  </li>
                </ul>





              </div>
            </div>
          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->

  <app-nav-footer></app-nav-footer>
</div>
