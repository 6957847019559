import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HelpDocComponent} from './help-doc/help-doc.component';
import {HelpPartComponent} from './help-part/help-part.component';
import {HelpAzieComponent} from './help-azie/help-azie.component';
import {HelpTutoComponent} from './help-tuto/help-tuto.component';


const routes: Routes = [
  // { path: '', component: HelpDocComponent, pathMatch: 'full' },
  { path: 'helpdoc', component: HelpDocComponent, pathMatch: 'full' },
  { path: 'helppart', component: HelpPartComponent, pathMatch: 'full' },
  { path: 'helpazie', component: HelpAzieComponent, pathMatch: 'full' },
  { path: 'helptutor', component: HelpTutoComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
